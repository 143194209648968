import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpHeaders,
} from '@angular/common/http';
import {
  ApolloModule,
  APOLLO_NAMED_OPTIONS,
  NamedOptions,
} from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EmptyRouteComponent } from './modules/empty-route/empty-route.component';
import { environment } from '../environments/environment';
import extractFiles from 'extract-files/extractFiles.mjs';
import isExtractableFile from 'extract-files/isExtractableFile.mjs';

import { TokenInterceptor } from '@deuna/bp-sh-helpers-ng';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { GlobalErrorComponentModule } from './modules/global-error/global-error.module';
import { ErrorStateService } from './modules/global-error/state/global-error.state.service';
import { handleCustomControlledError } from './modules/global-error/helpers/handleCustomControlledError';

@NgModule({
  declarations: [AppComponent, EmptyRouteComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ApolloModule,
    ToastModule,
    GlobalErrorComponentModule,
  ],
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory(
        httpLink: HttpLink,
        errorStateService: ErrorStateService
      ): NamedOptions {
        const errorLink = onError(({ graphQLErrors }) => {
          if (graphQLErrors) {
            console.log(graphQLErrors);
            graphQLErrors.forEach(({ extensions }) => {
              handleCustomControlledError(extensions, errorStateService);
            });
          }
        });
        const httpLinkCreate = httpLink.create({
          uri: environment.backend,
          extractFiles: (body) => extractFiles(body, isExtractableFile),
          headers: new HttpHeaders({
            'x-apollo-operation-name': 'cnbs',
          }),
        });
        return {
          newClientName: {
            name: 'backend',
            cache: new InMemoryCache(),
            defaultOptions: {
              watchQuery: {
                fetchPolicy: 'network-only',
                errorPolicy: 'ignore',
              },
              query: {
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
              },
            },
            link: ApolloLink.from([errorLink, httpLinkCreate]),
          },
        };
      },
      deps: [HttpLink, ErrorStateService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    MessageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}


