import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmptyRouteComponent } from './modules/empty-route/empty-route.component';
import { generalPath } from './constants/routes';
import { AuthGuard } from '@deuna/bp-sh-helpers-ng';
import { AuthorizationGuard } from '@deuna/bp-sh-security-ng';
import { CnbsRoles } from '@deuna/bp-sh-roles-nd';

const routes: Routes = [
  {
    path: generalPath,
    loadChildren: () =>
      import('./modules/cnbs/cnbs.module').then((m) => m.CnbsModule),
    canLoad: [AuthGuard, AuthorizationGuard],
    canActivateChild: [AuthGuard],
    canActivate: [AuthorizationGuard],
    data: {
      requiredRoles: [CnbsRoles.CN_EXECUTOR, CnbsRoles.CNB_READER, CnbsRoles.CNB_APPROVER],
    },
  },
  {
    path: '**',
    component: EmptyRouteComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' }],
})
export class AppRoutingModule {}
